import * as React from "react"
import { Link as GatsbyLink } from 'gatsby'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from 'styled-components'

const EAMainPage = () => (
  <Layout>
    <Seo title="Evolutionary astrology" />
    <h1>Evolutionary Astrology</h1>
    <StyledLink className="undecorated" to="/evolutionary-astrology/what-is-ea"><button>What Is Evolutionary Astrology?</button><span>➤</span></StyledLink>
    <StyledLink className="undecorated" to="/evolutionary-astrology/resources"><button>EA Resources</button><span>➤</span></StyledLink>
    <StyledLink className="undecorated" to="/evolutionary-astrology/abbreviations"><button>Common Abbreviations</button><span>➤</span></StyledLink>
  </Layout>
)

const StyledLink = styled(GatsbyLink)`
  background: linear-gradient(90deg, rgba(206,204,232,0.7) 0%, rgba(216,216,233,0.7) 100%);
    :hover {
      background: linear-gradient(90deg, rgba(206,204,232,1) 0%, rgba(216,216,233,1) 100%);
    }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  height: 4rem;
  text-align: start;
  font-size: 1.2rem;
  padding: .7rem; 
  @media screen and (min-width: 640px) {
    padding: 1rem;
    font-size: 1.5rem;
  }
`

export default EAMainPage
